// common---
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DynamicsContactForm from "../components/contactForm/dynamicsContactForm"
import MediaKit from "../components/mediaKit"
// --- end common

import BannerSection from "../components/bannerSection"
import MultiUp from "../components/multiUp"
import MultiUpItem from "../components/multiUpItem"

// Image imports
import bannerImage from "../images/npr.svg"
import jimAndMargery from "../images/Jim_and_Margery_studio_Dec20.jpg"
import classicalBSO from "../images/WCRB-Classical-Music-Radio-sponsorship.jpg"

const newsHeader = {
  title: "GBH 89.7",
  image: jimAndMargery,
}

const classicalHeader = {
  title: "CRB Classical 99.5",
  image: classicalBSO,
}

const RadioPage = () => (
  <Layout>
    <Seo
      title="Radio"
      isTitleExtended={true}
      description="GBH 89.7 offers award-winning news with local in-depth reporting and perspective. CRB Classical 99.5, the region's only 24-hour classical music station."
    />
    <BannerSection
      category="Radio"
      header='Benefit from the "Halo Effect" of Our News and Classical Radio Stations'
      image={bannerImage}
    />

    <div className="list-section">
      <MultiUp type="two-up">
        <MultiUpItem
          header={newsHeader}
          content="<ul><li><span>Award-winning news with local in-depth reporting</span></li><li><span>Popular national content from NPR</span></li><li><span>Covering the Commonwealth with five news bureaus</span></li></ul>"
        />
        <MultiUpItem
          header={classicalHeader}
          content="<ul><li><span>CRB Classical 99.5 is the region's only 24-hour classical music station.</span></li><li><span>50 full-length broadcasts by the Boston Symphony Orchestra</span></li><li><span>Numerous broadcasts of local classical performances</span></li></ul>"
        />
      </MultiUp>
    </div>

    <MediaKit />
    <DynamicsContactForm />
  </Layout>
)

export default RadioPage
